import { Suspense } from "react";
import { Col, Image, Row } from "react-bootstrap";
import LogoFullImage from "../assets/images/images/logo-full.png";

export function Component() {
  return (
    <Suspense fallback={<h1>Carregando...</h1>}>
      <section id="about" className="mt-5">
        <div className="container">
          <Row>
            <Col
              xs={12}
              md={10}
              className="d-flex flex-column align-items-center mb-2 mx-auto"
            >
              <Image width={240} src={LogoFullImage} className="mb-2" />
              <p className="fs-1 text-center my-5">
                Você precisa ser maior de idade
                <br />
                para participar dessa promoção.
              </p>
            </Col>
          </Row>
        </div>
      </section>
    </Suspense>
  );
}
